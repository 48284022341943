import axiosClientToken from "@/service/client/AxiosClientToken";
import axiosClient from "@/service/client/AxiosClient";
import {create, get, getForm, remove, update} from "@/service/Services";

const resource = "/person/certification";

export default {
    get: (params) => get(axiosClientToken, params, resource),
    getForm: (id) => getForm(axiosClientToken, id, resource),
    create: (payload) => create(axiosClientToken, payload, resource),
    update: (payload, id) => update(axiosClientToken, payload, id, resource),
    delete: (id) => remove(axiosClientToken, id, resource),
    searchCert:  (checksum) => {
        return axiosClient.get(`${resource}/search`, {params: checksum});
        // return get(axiosClient, checksum, `${resource}/search`);
    },
    getValidList:  (params) => {
        return axiosClientToken.get(`${resource}/valid/list`, {params: params});
    },
    cancel:  (id, params) => {
        return axiosClientToken.delete(`${resource}/cancel/${id}`, {params: params});
    },
};
