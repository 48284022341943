import axiosClient from "@/service/client/AxiosClientToken";

const resource = "/person/exam/results";

export default {
  update: (payload) => {
    return axiosClient.put(`${resource}/update`, payload);
  },

  getExamResultList: async (payload) => {
    return axiosClient.get(`${resource}/list`, { params: payload });
  },

  getExamResult: async (personExamResultId) => {
    return axiosClient.get(`${resource}/${personExamResultId}`);
  },
};
