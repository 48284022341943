import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { isEmpty } from "@/util/helper/Utils";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") {
      throw err;
    }
  });
};

const routes = [
  {
    name: "login",
    path: "/login/",
    component: () => import("@/views/Login/Login.vue"),
    meta: { isNotAuthenticated: true },
  },
  {
    name: "applications",
    path: "/applications",
    component: () => import("@/views/Applications/ViewApplications"),
    meta: { isAuthenticated: true },
  },
  {
    name: "approveApplications",
    path: "/approve_applications",
    component: () =>
      import("@/views/Applications/ViewApproveConfigureApplications"),
    meta: { isAuthenticated: true },
  },
  {
    name: "approveApplicationsCompleted",
    path: "/approve_applications_completed",
    component: () =>
      import("@/views/Applications/ViewApproveConfigureApplicationsCompleted"),
    meta: { isAuthenticated: true },
  },
  {
    path: "/temporary_applications_completed",
    component: () =>
      import("@/views/Applications/ViewTemporaryConfigureApplications"),
    meta: { isAuthenticated: true },
  },
  {
    path: "/sailor_applications",
    component: () => import("@/views/Applications/ViewApplicationSailor"),
    meta: { isAuthenticated: true },
  },
  {
    name: "showProfile",
    path: "/profile",
    component: () => import("@/views/Profile"),
    meta: { isAuthenticated: true },
  },
  {
    name: "showFormParameters",
    path: "/form_parameters",
    component: () => import("@/views/ParameterSetUp/Form"),
    meta: { isAuthenticated: true },
  },
  {
    name: "showFormValues",
    path: "/form_values",
    component: () => import("@/views/ParameterSetUp/FormValues"),
    meta: { isAuthenticated: true },
  },
  {
    name: "showFormValuesParameters",
    path: "/form_values/show_form_values/:id",
    component: () => import("@/views/ParameterSetUp/ValueParameters"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "showSetupParameter",
    path: "/show_setup_parameter/:id",
    component: () => import("@/views/ParameterSetUp/SetupParameters"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "showValueParameter",
    path: "/show_value_parameter/:id",
    component: () => import("@/views/ParameterSetUp/ValueParameters"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    path: "/configure_lov",
    component: () => import("@/views/ParameterSetUp/ConfigureLovGroups"),
    meta: { isAuthenticated: true },
  },
  {
    name: "showConfigureLovValues",
    path: "/configure_lov/show_lov_value/:id",
    component: () => import("@/views/ParameterSetUp/ConfigureLovValues"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "showConfigureApplications",
    path: "/configure_applications",
    component: () =>
      import("@/views/ConfigureApplications/ConfigureApplications"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "showConfigureApplicationsSetup",
    path: "/configure_applications/configure_applications_setup/:id",
    component: () =>
      import("@/views/ConfigureApplications/ConfigureApplicationsSetup"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "showConfigureApplicationsActions",
    path: "/configure_applications/actions/:id",
    component: () =>
      import("@/views/ConfigureApplications/ConfigureApplicationsActions"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "showApplicationMandatory",
    path: "/configure_applications/mandatory/:id",
    component: () =>
      import("@/views/ConfigureApplications/ApplicationMandatoryChecks"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "viewPersons",
    path: "/view_person",
    component: () => import("@/views/Persons/ViewPersons"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "editPersons",
    path: "/view_person/edit/:id",
    component: () => import("@/views/Persons/EditPerson"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },

  {
    name: "viewOperatorPersons",
    path: "/view_operator_person",
    component: () => import("@/views/Persons/ViewPersons"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "editOperatorPersons",
    path: "/operator_person/view_operator_person/edit/:id",
    component: () => import("@/views/Persons/EditPerson"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "createOperatorPersons",
    path: "/operator_person/view_operator_person/create",
    component: () => import("@/views/Persons/EditPerson"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },

  {
    name: "viewOperators",
    path: "/operator_person",
    component: () => import("@/views/Persons/ViewOperators"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "viewOperatorRole",
    path: "/operator_person/operator_role",
    component: () => import("@/views/Persons/ViewOperatorRole"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  // menu
  {
    name: "menu",
    path: "/menu",
    component: () => import("@/views/Menu/Menu"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "role",
    path: "/role",
    component: () => import("@/views/Menu/Role"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "menuRole",
    path: "/role/menu_role",
    component: () => import("@/views/Menu/MenuRole"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "printReports",
    path: "/printReports",
    component: () => import("@/views/Report/PrintReports.vue"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "viewReport",
    path: "/report",
    component: () => import("@/views/Report/ViewReport"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "viewReportParams",
    path: "/report/report_param/:id",
    component: () => import("@/views/Report/ViewReportParam"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "viewReportRole",
    path: "/report/report_role/:id",
    component: () => import("@/views/Report/ViewReportRole"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "audit",
    path: "/audit",
    component: () => import("@/views/Audit/Audit"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "personHealth",
    path: "/person_health/edit",
    component: () => import("@/views/PersonHealth/EditPersonHealth"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "personExamResults",
    path: "/person_exam_results/edit",
    component: () => import("@/views/PersonExamResults/PersonExamResults"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "certificationSearch",
    path: "/certification/search",
    component: () => import("@/views/Certification/CertificationSearch"),
    props: {
      default: true,
    },
    meta: { isNotAuthenticated: true },
  },
  {
    name: "personHealthSecond",
    path: "/person_health/editSecond",
    component: () => import("@/views/PersonHealth/EditPersonHealthSearch"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "personExamResultsSecond",
    path: "/person_exam_results/editSecond",
    component: () =>
      import("@/views/PersonExamResults/PersonExamResultsSearch"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "variables",
    path: "/variable",
    component: () => import("@/views/Variable/Variable"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  {
    name: "authDoc",
    path: "/auth/doc",
    component: () => import("@/views/AuthDoc/AuthDoc"),
    props: {
      default: true,
    },
    meta: { isAuthenticated: true },
  },
  // {
  //   name: "notification",
  //   path: "/notification",
  //   component: () => import("@/views/Notifications.vue"),
  //   props: {
  //     default: true,
  //   },
  //   meta: { isAuthenticated: true },
  // },
  {
    name: "sso",
    path: "/sso",
    component: () => import("@/views/Sso/Sso.vue"),
    meta: { isNotAuthenticated: true },
  },
  {
    path: "",
    redirect: `/login`,
    meta: { isNotAuthenticated: true },
  },
  {
    name: "usefullLinks",
    path: "/usefullLinks",
    component: () => import("@/views/Login/UsefulLinks.vue"),
    meta: { isNotAuthenticated: true },
  },
  {
    name: "oroiXrisis",
    path: "/oroiXrisis",
    component: () => import("@/views/Login/OroiXrisis.vue"),
    meta: { isNotAuthenticated: true },
  },
];

const router = new VueRouter({
  mode: "history",
  //mode: "hash",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.isAuthenticated)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.getters["auth/getToken"] == null) {
      next("/login");
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.isNotAuthenticated)) {
    if (store.getters["auth/isLoggedIn"]) {
      next("/");
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});
router.afterEach(async (to, from) => {
  let result = store.state.base.excludeRouter.filter((r) => {
    if (from.name == r.main) {
      return r;
    } else if (r.internal.includes(to.name) || r.internal.includes(from.name)) {
      return r;
    }
  });
  if (!isEmpty(result)) {
    if (
      !result[0].main.includes(to.name) &&
      !result[0].internal.includes(to.name)
    ) {
      await store.dispatch("setOptions", {});
    }
  } else {
    await store.dispatch("setOptions", {});
  }
});
export default router;
