export default {
    namespaced: true,
    state: {
        formId: null,
        formSetupDescription: null,
        formCategoryDescription: null,
    },
    mutations: {
        CLEAR_FORM_SETUP(state) {
            state.formId = null;
            state.formSetupDescription = null;
            state.formCategoryDescription = null;

        },
        SET_EDIT_FORM(state, payload) {
            state.formId = payload.formId;
            state.formSetupDescription = payload.formSetupDescription;
            state.formCategoryDescription = (payload.formCategoryDescription != null || payload.formCategoryDescription != undefined) ? payload.formCategoryDescription : null;
        },
    },
    actions: {
        async clearFormSetup({ commit }) {
            commit("CLEAR_FORM_SETUP");
        },
        setEditForm({commit}, payload) {
            commit("SET_EDIT_FORM", payload);
        },
    },
    getters: {
        formId: (state) => state.formId
    },
};
