export default {
    namespaced: true,
    state: {
        personId: null,
        person: null,
        supportCertification: true,
        supportAddress: true,
        supportAuthDocs: true,
    },
    mutations: {
        CLEAR_PERSONS(state) {
            state.personId = null;
            state.person = null;
            state.supportCertification = true;
            state.supportAddress = true;
            state.supportAuthDocs = true;

        },
        SET_EDIT_PERSON_VALUE(state, payload) {
            state.personId = payload.person.id;
            state.person = payload.person;
            state.supportCertification = payload.supportCertification;
        },
    },
    actions: {
        async clearPersons({ commit }) {
            commit("CLEAR_PERSONS");
        },
        setEditPersonValue({commit}, payload) {
            commit("SET_EDIT_PERSON_VALUE", payload);
        },
    },
    getters: {
        getPersonId: (state) => state.personId,
    },
};
