import FormRepo from "@/service/common/form/Form";
import FormSetupRepo from "@/service/common/form/FormSetup";
import FormValueRepo from "@/service/common/form/FormValue";
import CommonApplicationAction from "@/service/common/CommonApplicationAction";
import LovGroupRepo from "@/service/lov/LovGroup";
import LovValuesRepo from "@/service/lov/LovValues";
import PersonAddressRepo from "@/service/person/PersonAddress";
import PersonCertificationRepo from "@/service/person/PersonCertification";
import PersonAuthDocRepo from "@/service/person/PersonAuthDoc";
import CommonOnlineApplicationRepo from "@/service/common/online/CommonOnlineApplication";
import CommonOnlineApplicationSetupRepo from "@/service/common/online/CommonOnlineApplicationSetup";
import CommonOnlineApplicationActionRepo from "@/service/common/online/CommonOnlineApplicationAction";
import NotificationRepo from "@/service/notification/Notification";
import AuthRepo from "@/service/auth/Auth";
import PersonRepo from "@/service/person/Person";
import PersonApplicationRepo from "@/service/person/PersonApplication";
import MenuRepo from "@/service/menu/Menu";
import MenuRoleRepo from "@/service/menu/MenuRole";
import RoleRepo from "@/service/menu/Role";
import AuditingRepo from "@/service/audit/Audit";
import ReportRepo from "@/service/report/Report";
import ReportParamRepo from "@/service/report/ReportParam";
import ReportRoleRepo from "@/service/report/ReportRole";
import PersonHealthRepo from "@/service/personHealth/PersonHealth";
import PersonExamResultsRepo from "@/service/person/PersonExamResults";
import MandatoryRepo from "@/service/common/CommonAppplicationMandatoryChecks";
import VariableRepo from "@/service/variable/Variable";
import JasperReport from "@/service/report/JasperReport";

const repositories = {
    form: FormRepo,
    formSetup: FormSetupRepo,
    formValue: FormValueRepo,
    lovGroup: LovGroupRepo,
    lovValues: LovValuesRepo,
    personAddress: PersonAddressRepo,
    personAuthDoc: PersonAuthDocRepo,
    personCertification: PersonCertificationRepo,
    person: PersonRepo,
    personApplication: PersonApplicationRepo,
    commonOnlineApplication: CommonOnlineApplicationRepo,
    commonOnlineApplicationSetup: CommonOnlineApplicationSetupRepo,
    commonOnlineApplicationAction: CommonOnlineApplicationActionRepo,
    notification: NotificationRepo,
    auth: AuthRepo,
    menu: MenuRepo,
    role: RoleRepo,
    menuRole: MenuRoleRepo,
    audit: AuditingRepo,
    report: ReportRepo,
    jasperReport: JasperReport,
    reportParam: ReportParamRepo,
    reportRole: ReportRoleRepo,
    commonApplicationAction: CommonApplicationAction,
    personHealth: PersonHealthRepo,
    personExamResults: PersonExamResultsRepo,
    commonApplicationMandatoryChecks: MandatoryRepo,
    variable: VariableRepo
};

export default {
    get: (name) => repositories[name],
};
