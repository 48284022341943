import Repository from "@/service/Repository";

export const INVALID_DATE = "Invalid date";
const JasperReportRepo = Repository.get("jasperReport");
export const parseListItems = (items, listType) => {
  if (listType != null) {
    return items
      .filter((x) => x.lovGroupId == listType)
      .map((x) => {
        return { ...x, cd: parseInt(x.cd) };
      });
  } else {
    return items
      .filter(
        (x) =>
          Object.prototype.hasOwnProperty.call(x, "label") ||
          Object.prototype.hasOwnProperty.call(x, "description")
      )
      .map((x) => {
        return { ...x, cd: parseInt(x.cd) };
      });
  }
};

export const parseListItemsToString = (items, listType) => {
  if (listType != null) {
    if (typeof listType === "object" && Object.keys(listType).length > 0) {
      if (!isEmpty(items)) {
        return items
          .filter((x) => listType.includes(x.lovGroupId))
          .map((x) => {
            return { ...x, cd: x.cd };
          });
      } else {
        return [];
      }
    } else {
      if (!isEmpty(items)) {
        return items
          .filter((x) => x.lovGroupId == listType)
          .map((x) => {
            return { ...x, cd: x.cd };
          });
      } else {
        return [];
      }
    }
  } else {
    if (!isEmpty(items)) {
      return items
        .filter(
          (x) =>
            Object.prototype.hasOwnProperty.call(x, "label") ||
            Object.prototype.hasOwnProperty.call(x, "description")
        )
        .map((x) => {
          return { ...x, cd: x.cd };
        });
    } else {
      return [];
    }
  }
};

export const formatDate = (value, formatter) => {
  if (value != null && value !== "" && value !== undefined) {
    return new Date(value).toLocaleDateString(formatter);
  } else {
    return null;
  }
};

export const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    value === "null" ||
    value === "undefined" ||
    Number.isNaN(value) ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

export const normalizeGreek = (text) => {
  return text !== undefined && text !== null
    ? text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    : "";
};

/**
 * Merge 2 arrays without duplicates records
 */
export const mergeArrays = (array1, array2) => {
  return array1.concat(array2.filter((item) => array1.indexOf(item) < 0));
};
export const reFormatDate = (date) => {
  if (!date) return null;
  const [day, month, year] = date.split("/");
  return `${year}-${month}-${day}`;
};
export const reFormatISODate = (date) => {
  if (!date) return null;
  const [year, month, day] = date.split("-");
  return `${day}/${month}/${year}`;
};

export const downloadJasperPrint = async (
  item,
  jasperPrintParams,
  outputName,
  fieldName
) => {
  let body = {
    id: item[fieldName],
    outputName: outputName,
    jasperPrintParams,
  };

  const response = await JasperReportRepo.download(body, 1);
  var fileURL = window.URL.createObjectURL(
    new Blob([response.data], {
      type: "application/pdf",
    })
  );
  var fileLink = document.createElement("a");

  fileLink.href = fileURL;
  fileLink.setAttribute("download", body.outputName + ".pdf");
  document.body.appendChild(fileLink);
  fileLink.click();
};

