export default {
    namespaced: true,
    state: {
        allActions: [],
    },

    mutations: {
        SET_ALL_ACTIONS(state, payload) {
            state.allActions = payload;
        },
        CLEAR_ALL_ACTIONS(state) {
            state.allActions = [];
        },
    },

    actions: {
        setAllActions({commit}, payload) {
            commit("SET_ALL_ACTIONS", payload);
        },
        async clearAllActions({commit}) {
            commit("CLEAR_ALL_ACTIONS");
        },
    },
    getters: {
        getAllActions: (state) => () => state.allActions,
    },
};
