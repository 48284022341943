import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import base from "@/store/modules/base";
import * as auth from "@/store/modules/auth";
import errors from "@/store/modules/errors";
import notifications from "@/store/modules/notifications";
import formSetup from "@/store/modules/formSetup";
import configureApplications from "@/store/modules/configureApplications";
import locale from "@/store/modules/locale";
import applications from "@/store/modules/applications";
import persons from "@/store/modules/persons";
import dynamicList from "@/store/modules/dynamicList";
import roles from "@/store/modules/roles";
import menus from "@/store/modules/menus";
import files from "@/store/modules/files";
import commonAction from "@/store/modules/commonAction";
import axios from "@/service/client/AxiosClient";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        lang: "GR"
    },
    mutations: {
        SET_LANG(state, payload) {
            state.lang = payload;
        },

        SET_LOGOUT(state, dispatch) {
            dispatch('dynamicList/clearDynamicList', {root: true});
            dispatch('commonAction/clearAllActions', {root: true});
            dispatch('base/clearBase', {root: true});
            dispatch('persons/clearPersons', {root: true});
            // dispatch('notifications/clearNotifications', {root: true});
            dispatch('formSetup/clearFormSetup', {root: true});
            dispatch('auth/logout', {root: true});
            delete axios.defaults.headers.common["Authorization"];
            window.localStorage.clear();
        }
    },
    actions: {
        logout({commit, dispatch}) {
            commit('SET_LOGOUT', dispatch)
        },
        setLang({commit}, data) {
            commit('SET_LANG', data)
        }
    },
    getters: {},
    modules: {
        base,
        auth,
        errors,
        notifications,
        formSetup,
        configureApplications,
        locale,
        applications,
        persons,
        dynamicList,
        roles,
        menus,
        files,
        commonAction,
    },
    plugins: [createPersistedState()],
});

//  plugins: [createPersistedState({
//     storage: window.sessionStorage,
//   })],