<template>
  <v-app class="my-application">
    <Appbar v-if="isLoggedIn" />
    <v-main>
      <router-view />
      <v-snackbar
          v-model="snackbar.open"
          :color="snackbar.color"
          absolute
          top
          class="pt-10"
      >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn
              dense
              text
              icon
              x-small
              v-bind="attrs"
              @click="closeSnackbar"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
    <Footer v-if="isLoggedIn" />
  </v-app>
</template>

<script>
import {mapGetters, mapMutations, mapActions, mapState} from "vuex";
import axios from "axios";

import Appbar from "./components/Appbar.vue";
import Footer from "./components/Footer.vue";
import store from "@/store";
import GR from "@/locales/GR.json"
import EN from "@/locales/EN.json"
export default {
  components: {
    Appbar,
    Footer,
  },
  computed: {
    snackbar: {
      get() {
        return this.$store.state.base.snackbar;
      },
      set(value) {
        this.toggleSnackbar(value);
      },
    },
    ...mapGetters({isLoggedIn:["auth/isLoggedIn"]}),
    ...mapState({
      token: (state) => state.auth.token,
    }),
  },
  async created() {
    await this.getErrors();
    this.toggleSnackbar({
      open: false,
      text: null,
      color: null,
    });

    try {
      if (this.isLoggedIn) {
        await this.$store.dispatch('auth/refreshToken');
      }
      store.state.locale.GR = GR;
      store.state.locale.EN = EN;
      // axios.get(`./locales/GR.json`).then((response) => {
      //   store.state.locale.GR = response.data;
      // });
      // axios.get(`./locales/EN.json`).then((response) => {
      //   store.state.locale.EN = response.data;
      // });
    } catch (e) {
      throw e;
    }
    await this.$store.dispatch('setApproveTabs')
  },
  methods: {
    ...mapMutations(["toggleSnackbar"]),
    ...mapActions(["logout", "getErrors"]),
    closeSnackbar() {
      this.toggleSnackbar({
        open: false,
        text: null,
        color: null,
      });
    },
  },
};
</script>


<style lang="scss">
//======VUETIFY OVERRIDES(Bootstrap Styles)========
.v-main__wrap {
  display: flex;
  background-color: #ebf2f6;
}

.text-h6,
.font-weight-medium {
  font-weight: 600 !important;
}

.font-weight-regular {
  font-weight: 400 !important;
}

.v-btn:not(.v-btn--icon) {
  letter-spacing: normal !important;
  text-transform: none !important;
  border-radius: 2px !important;
}

.v-text-field--outlined fieldset {
  border-color: #e9ecef !important;
  border-radius: 1px !important;

  transition: border-color 0.15s ease-in-out !important;
}

.v-input--is-focused fieldset {
  border: 1px solid #ccc !important;
}

.v-input--selection-controls {
  margin-top: 0px !important;
  padding: 0px !important;
}

table thead th {
  // font-size: 0.875rem !important;
  font-weight: 600 !important;
  letter-spacing: normal !important;
}

.v-slide-group__wrapper {
  max-width: 1500px !important;
  width: 100% !important;
}

// striped tables
// .theme--light.v-data-table tbody tr:nth-of-type(odd) {
//   background-color: rgba(0, 0, 0, 0.05) !important;
// }

//======GLOBAL STYLES=======
.component-wrapper {
  flex-grow: 1;
  padding: 20px;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");
$font-family: "Open Sans", sans-serif;
.my-application {
  *:not(.v-icon),
  *:not(.v-icon):before,
  *:not(.v-icon):after {
    font-family: $font-family !important;
  }
}
</style>
